<template>
  <div>
    <div class="a-product-option-bundle">
      <SfAlert
        v-if="errorMessage"
        :message="errorMessage"
        type="danger"
      />
      <SfHeading
        :title="option.title"
        v-show="showOptionTitle"
        :level="5"
        class="sf-heading--left"
      />
      <ASelect @input="setProductOptionId" v-model="productOptionId" v-if="option.type === 'select'">
        <ASelectOption v-for="link in option.product_links" :value="`${link.id}`" :key="link.id">
          {{ `${link.qty > 1 ? `${link.qty} X ` : ''}${link.product && link.product.name || ''}` }}
        </ASelectOption>
      </ASelect>
      <form v-if="(option.type === 'checkbox'|| option.type === 'radio') && option.required === false">
        <h4>
          {{ option.title }}
        </h4>
        <label
          :for="link.id"
          class="checkbox-links"
          v-for="link in option.product_links"
          :key="link.id"
        >
          <span>{{ link.product.name }}</span>
          <input
            type="checkbox"
            name="checkbox"
            :value="`${link.id}`"
            v-model="productCheckboxId"
            :id="link.id"
            @click="uncheck($event, link.id)"
          >
          <span class="checkmark" />
        </label>
      </form>
    </div>
  </div>
</template>

<script>
import { Logger } from '@vue-storefront/core/lib/logger';
import { ProductBundleOption } from '@vue-storefront/core/modules/catalog/components/ProductBundleOption';
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';
import SfAlert from '@storefront-ui/vue/src/components/molecules/SfAlert/SfAlert.vue';
import ASelect from '~/theme/components/atoms/a-select';
import ASelectOption from '~/theme/components/atoms/a-select-option';
import { mapState, mapGetters } from 'vuex';
import { formatPrice } from '~/theme/helpers';

export default {
  mixins: [ProductBundleOption],
  name: 'AProductOptionBundle',
  components: {
    SfHeading,
    SfAlert,
    ASelect,
    ASelectOption
  },
  props: {
    showOptionTitle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      quantity: 1
    }
  },
  computed: {
    ...mapState({
      bundleOptions: state => state.product.current_bundle_options
    }),
    ...mapGetters({
      getCurrentProduct: 'product/getCurrentProduct'
    }),
    value () {
      const { product_links } = this.option
      if (Array.isArray(product_links)) {
        return product_links.find(product => product.id === parseInt(this.productOptionId))
      }
      return product_links
    }
  },
  methods: {
    formatPrice (price) {
      return formatPrice(price)
    },
    setProductOptionId (link) {
      this.bundleOptionChanged()
    },
    setDefaultValues () {
      const { product_links } = this.option
      if (product_links) {
        const defaultOption = Array.isArray(product_links)
          ? product_links.find(pl => pl.is_default)
          : product_links
        if (!product_links[0]) {
          Logger.error('Product links doesn\'t contain any options, cannot set default value', 'Bundle option', { product_links })()
          return
        }
        this.productOptionId = defaultOption ? `${defaultOption.id}` : null
        this.quantity = defaultOption ? defaultOption.qty : 1
        this.productCheckboxId = this.productOptionId
      }
    },
    uncheck ($event, val) {
      this.isOptionCheckbox = $event.target.checked
      this.productOptionId = val
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.a-product-option-bundle {
  --select-height: auto;
  --select-padding: var(--spacer-xs) 0 var(--spacer-xs) 0;
  --select-error-message-height: 0;
  margin: 0;
  width: 100%;
}
.label {
  display: flex;
  justify-content: space-between;
}
::v-deep {
  .a-product-quantity {
    margin-top: 1rem;
    height: 3rem;
    min-height: 3rem;
  }
}
.sf-divider {
  margin-top: var(--spacer-xl);
}

.checkbox-links {
  position: relative;
  padding-left: 30px;
  display: flex;
  margin-bottom: 10px;
  margin-left: 20px;
  input {
    position: absolute;
    cursor: pointer;
    height: 20px;
    width: 20px;
    opacity: 0;
    top: -2px;
    left: 0;
    z-index: 1;
  }
  .checkmark {
    top: -2px;
    left: 0;
    position: absolute;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-color: #f4f4f4;
    border-radius: 4px;
    border: solid 1px #ddd;
  }
  &:hover {
     input ~ .checkmark {
      border: solid 1px rgb(205, 198, 198);
    }
  }
  input:checked ~ .checkmark {
    background-color: #333;
    border-color: #333;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 7px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  > strong {
    color: #ff1616;
    display: flex;
    margin-left: auto;
   > span {
    text-decoration: line-through;
    color: #5f5f5f;
    margin-left: 5px;
   }
  }
  > span {
    margin-right: 15px;
  }
}
</style>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.m-product-options-bundle {
  > div {
    width: 100%;
  }
}
</style>

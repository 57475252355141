<template>
  <div class="m-product-options-bundle">
    <AProductOptionBundle
      v-for="option in bundleOptionsInStock"
      :key="('bundleOption_' + option.option_id)"
      v-show="!hideOption(option)"
      :option="option"
      @option-changed="optionChanged"
      :error-messages="errorMessages"
    />
  </div>
</template>

<script>
import { ProductBundleOptions } from '@vue-storefront/core/modules/catalog/components/ProductBundleOptions'
import AProductOptionBundle from '~/theme/components/atoms/a-product-option-bundle'
import { isInStock } from '@vue-storefront/core/modules/catalog/helpers/stock'
export default {
  mixins: [ProductBundleOptions],
  components: {
    AProductOptionBundle
  },
  computed: {
    bundleOptionsInStock () {
      return this.product.bundle_options.map((option) => {
        const product_links = option.product_links.filter(productLink => {
          if (!productLink.product) return null;
          const stockInfo = productLink.stock ? productLink : productLink.product
          return isInStock(stockInfo)
        })
        return { ...option, product_links }
      })
    }
  },
  created () {
    this.$store.dispatch('product/setBundleOptions', {
      product: this.product, bundleOptions: {}
    })
  },
  methods: {
    hideOption: ({ product_links, required }) => {
      return product_links?.length < 1 && required === true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-product-options-bundle {
  display: grid;
  place-items: center;
  padding: 0;
}
</style>
